@use 'sass:color' {}


body {
 }  // padding-top: 5rem

//.jekyll-theme-monemporda

.main {
  margin-top: 4rem;
  img {
    max-width: 100%; } }

.section-heading {
  // padding-top: 3rem
  padding-bottom: 1.5rem;
  font-weight: 700; }

.img-circle {
  border-radius: 100%; }

.list-group svg {
  height: 1.5rem;
  fill: $primary;
  &:hover {
    fill: darken($primary, 5); } }
