@use 'sass:color' {}

.header {
  line-height: 1;
  border-bottom: 1px solid $body-color;
  background: #f8f3ed; }

.nav-header-logo:hover {
  text-decoration: none; }
.nav-header {
  .icon {
    height: 1.5rem;
    fill: $primary;
    &:hover {
      fill: darken($primary, 5); } } }

.navbar {
  background: $secondary;
  padding: 0;
  margin-right: -15px;
  margin-left: -15px;
  .navbar-nav .nav-link {
    padding: 1rem .5rem;
    font-weight: 700;
    font-size: 1.1rem;
    transition: all 0.5s ease 0s;
    &:hover {
      background: $primary; } } }
