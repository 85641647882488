@charset "utf-8";

$body-bg: #EDFCF2;
$body-color: #2d2d2d;
$primary: #168e41;
$secondary: #c0dac3;
$navbar-light-color:                $body-color;
$navbar-light-hover-color:          $body-bg;
$navbar-light-active-color:         #FFF;
$navbar-light-disabled-color:       rgba($body-color, .5);

$font-family-sans-serif: 'PT Sans', sans-serif;
$headings-font-family: 'Amaranth', sans-serif;

@import "vendor/bootstrap/stylesheets/bootstrap",
        "vendor/modaal/modaal",
        "base",
        "page",
        "card-monemporda",
        "floating-labels",
        "nav",
        "footer";
