.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > textarea,
.form-label-group > select,
.form-label-group > label {
  padding: .75rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
}
.form-label-group > select.custom-select {
  height: auto;
}
.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: $primary;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group {
  input::placeholder,
  input::-moz-placeholder,
  input::-webkit-input-placeholder,
  input:-ms-input-placeholder,
  input::-ms-input-placeholder,
  textarea::placeholder,
  textarea::-moz-placeholder,
  textarea::-webkit-input-placeholder,
  textarea:-ms-input-placeholder,
  textarea::-ms-input-placeholder,
  select::placeholder,
  select::-moz-placeholder,
  select::-webkit-input-placeholder,
  select:-ms-input-placeholder,
  select::-ms-input-placeholder
  {
    color: transparent;
  }
}

.form-label-group input:not(:placeholder-shown),
.form-label-group textarea:not(:placeholder-shown){
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label{
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: $body-color;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder,
  .form-label-group textarea::-ms-input-placeholder {
    color: $body-color;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder,
  .form-label-group textarea:-ms-input-placeholder  {
    color: $body-color;
  }
}
