.card-monemporda {
  margin-top: 30%;
  margin-bottom: 3rem;
  text-align: center;
  border: none;
  border-bottom: $secondary .7rem solid;
  transition: all 0.5s ease 0s;
  color: $body-color;
  &:hover {
    opacity: .8; }
  .card-img-top {
    margin: -25% auto 0;
    max-width: 80%;
    border-radius: 100%;
    border: .4rem solid $primary; }
  .card-title {
    font-weight: 400; }
  .card-text {
    font-size: .8rem; } }

.card-columns {
  @include media-breakpoint-up(sm) {
    column-count: 2; }

  @include media-breakpoint-up(md) {
    column-count: 3; }

  @include media-breakpoint-only(lg) {
    column-count: 4; }

  @include media-breakpoint-only(xl) {
    column-count: 5; } }
